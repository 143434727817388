<!--
 * @Author: PoJun
 * @Date: 2023-08-08 10:48:34
 * @LastEditors: PoJun
 * @LastEditTime: 2023-08-08 14:30:22
 * @Message: Nothing
-->
<template>
    <div>
        <!-- header pages -->
        <div class="header-pages">
            <div class="pages-title">
                <div class="container">
                    <span>We Are Professional</span>
                    <h2>Faq</h2>
                </div>
            </div>
        </div>
        <!-- end header pages -->

        <!-- faq -->
        <div class="faq">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <div class="entry">
                            <h5>How many month items update?</h5>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
                                tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
                                consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper
                                mattis, pulvinar dapibus.
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="entry">
                            <h5>Change logo on website</h5>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
                                tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
                                consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper
                                mattis, pulvinar dapibus.
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="entry">
                            <h5>Can I pay with paypal?</h5>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
                                tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
                                consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper
                                mattis, pulvinar dapibus.
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="entry">
                            <h5>How to make website easy edit?</h5>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
                                tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
                                consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper
                                mattis, pulvinar dapibus.
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="entry">
                            <h5>Where to get contact support?</h5>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
                                tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
                                consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper
                                mattis, pulvinar dapibus.
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="entry">
                            <h5>How to make auto update?</h5>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
                                tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
                                consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper
                                mattis, pulvinar dapibus.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end faq -->
    </div>
</template>

<script>
export default {
    name: "faq",
    mounted() {},
};
</script>

<style scoped></style>
